.customCriteriaCard.criteriaCard {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.customCriteriaCard .criteriaCardBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
}

.customCriteriaCard .criteriaCardText {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.customCriteriaCard .criteriaTitle {
    font-weight: bold;
    margin-bottom: 8px;
    /* Espacio antes de los criterios */
    text-align: left;
}

.customCriteriaCard .criteriaText {
    margin-top: 4px;
    text-align: left;
}

.customCriteriaCard .actionButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
}