.customEpicCard.epicCard {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.customEpicCard .epicCardBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
}

.customEpicCard .epicCardText {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.customEpicCard .epicText,
.customEpicCard .criteriaTitle,
.customEpicCard .criteriaText {
    text-align: left;
    margin-left: 0;
}

.customEpicCard .epicTitle {
    font-weight: bold;
    font-size: 1.2rem;
}

.customEpicCard .epicDescription {
    margin-top: 8px;
}


.customEpicCard .actionButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
}