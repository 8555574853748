.intro-section .home-title-text {
    font-size: 2.5rem;
    font-weight: 600;
    color: #0b2136;
    letter-spacing: 0.015em;
    margin-bottom: 1em;
    padding-top: 0.5em;
}

.intro-section .intro-text {
    font-size: 1.2rem;
    color: #123456;
    letter-spacing: 0.015em;
    margin-bottom: 1.5em;
    padding-top: 0.5em;
}

.intro-section .intro-image {
    max-width: 80%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 1em;
}

.start-button {
    margin-top: 1.5em;
    font-size: 1.2rem;
    padding: 0.75em 1.5em;
}

/* Ajustes en customMaterial.css */
@media (max-width: 600px) {
    .intro-section .home-title-text {
        font-size: 1.8rem;
        margin-bottom: 0.5em;
    }

    .intro-section .intro-text {
        font-size: 1rem;
        margin-bottom: 1em;
    }

    .intro-section .intro-image {
        max-width: 100%;
    }

    .start-button {
        font-size: 1rem;
        padding: 0.5em 1em;
    }
}