/* Dashboard.css */

.list-item {
    cursor: pointer;
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: inherit;
    border: none;
    transition: background-color 0.3s;
}

.list-item:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.list-item-text {}