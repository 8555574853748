.welcome-card {
    text-align: center;
    padding: 2rem;
    max-width: 600px;
    margin: auto;
    border-radius: 8px;
    width: 100%;
    /* Asegura que la tarjeta use todo el ancho disponible */
}

.welcome-card-title {
    margin-bottom: 1rem;
}

.welcome-card-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    /* Asegura que la imagen cubra el espacio sin distorsión */
}

.welcome-card-buttons {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    /* Centra los botones */
}

.welcome-card-buttons .MuiButton-containedPrimary {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    min-width: 120px;
    flex: 1 1 0;
    /* Asegura que todos los botones tengan el mismo tamaño */
    max-width: 200px;
    /* Opcional: limita el ancho máximo */
}

@media (max-width: 600px) {
    .welcome-card-buttons {
        flex-direction: column;
        align-items: center;
    }

    .welcome-card-buttons .MuiButton-containedPrimary {
        width: 100%;
        font-size: 0.75rem;
        padding: 0.5rem;
        white-space: nowrap;
        /* Evita que el texto se salga del botón */
    }
}