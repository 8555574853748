:root {
  --texto: #343A40;
  /* Gris oscuro */
  --navbar-bg: var(--color-primario);
  /* Usando el color primario para el navbar */
  --navbar-text: whitesmoke;
  /* Blanco para texto del navbar */
  --btn-hover: #0056b3;
  /* Azul oscuro para hover en botones */
  --btn-secundario: #6270B8;
  /* Verde oscuro para botones secundarios */
}

/* We reset some default styles and apply Ubuntu as the main font */
html,
body {
  background-color: #ffffff;
  /* Fondo completamente blanco */
  background-image: none;
  /* Make sure the gradient covers the full viewport height */
}

.intro-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1em;
  padding-bottom: 0em;
  /* background-color: var(--fondo); */
  /* O cualquier color que prefieras */
}

.intro-image {
  /* Your original styles for .intro-image */
}

.aboutUs-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 40px auto;
}

.useCases-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 40px auto;
}

.termsAndConditions-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 40px auto;
}

.privacyTerms-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 40px auto;
}