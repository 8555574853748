/* Estilos para el contenedor de las tarjetas */
.content-section .advantage-section-title {
    font-size: 1.5rem;
    color: #666;
    margin-top: 4rem;
}

.advantage-cards.feature-cards-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

/* Estilos para cada tarjeta */
.advantage-cards .feature-card {
    flex: 1 0 260px;
    max-width: 260px;
    height: auto;
    min-height: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: transform 0.3s, box-shadow 0.3s;
}

.advantage-cards .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Estilos para los iconos dentro de las tarjetas */
.advantage-cards .feature-icon {
    font-size: 30px;
    color: #1976d2;
    margin-bottom: 8px;
}

/* Estilos para los títulos de las tarjetas */
.advantage-cards .feature-title {
    font-size: 1.25rem;
    color: #0b2136;
}

/* Estilos para las descripciones de las tarjetas */
.advantage-cards .feature-description {
    list-style: circle inside;
    padding-left: 10px;
    font-size: 0.9rem;
    color: #666;
}

.advantage-cards .feature-description li {
    margin-bottom: 4px;
    text-align: left;
}

.advantage-cards .feature-title,
.advantage-cards .feature-description {
    text-align: left;
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 800px) {
    .advantage-cards.feature-cards-container {
        flex-direction: column;
    }

    .advantage-cards .feature-card {
        flex-basis: 90%;
        max-width: none;
        margin: 10px 0;
        min-height: auto;
    }

    .content-section .advantage-section-title {
        font-size: 1.4rem;
        color: #666;
        margin-top: 2rem;
    }
}

/* Responsividad para pantallas grandes */
@media (min-width: 800px) {
    .advantage-cards.feature-cards-container {
        flex-wrap: nowrap;
    }

    .advantage-cards .feature-card {
        flex-basis: calc(33.33% - 40px);
        max-width: calc(33.33% - 40px);
    }
}