.customStoryCard.storyCard {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.customStoryCard .storyCardBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
}

.customStoryCard .storyCardText {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.customStoryCard .storyText,
.customStoryCard .criteriaTitle,
.customStoryCard .criteriaText {
    text-align: left;
    margin-left: 0;
}

.customStoryCard .criteriaTitle {
    font-weight: bold;
    margin-top: 16px;
}

.customStoryCard .criteriaText {
    margin-top: 8px;
}

.customStoryCard .actionButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
}